<script setup lang="ts">
import { scrollToPosition } from '@mop/shared/utils/util';
import type { CmsStoryModel } from '@/types/cms';

defineOptions({
  name: 'BlogOverview',
});

const classNameRef = ref(['']);
const { $gtm } = useNuxtApp();
const { getCmsStoryList, cmsStoryListModelRef, loadingRef } = useMopCms();
const { getLocalePathByPage } = useMopRouter();
const { initTransition } = useMopPageTransitionClient();
const route = useRoute();
const router = useRouter();
const storyListRef: Ref<CmsStoryModel[]> = ref([]);
const totalPagesRef: Ref<number> = ref(0);
const perPage = 10;

initTransition(classNameRef, loadingRef);

onMounted(() => {
  watch(
    () => route.query?.page,
    async (page) => {
      scrollToPosition(0);

      await getCmsStoryList('/career/career-blog', {
        is_startpage: 0,
        per_page: perPage,
        // @ts-ignore
        page,
        sort_by: 'content.publishedDate:desc',
      });

      if (cmsStoryListModelRef.value.hasError()) {
        throw createError({
          statusCode: 404,
          message: 'PAGE_NOT_FOUND',
        });
      } else {
        $gtm.trackPageView({
          pageType: 'Blog',
        });
      }
      storyListRef.value = cmsStoryListModelRef.value.getStoryModelList();
      totalPagesRef.value = Math.ceil(cmsStoryListModelRef.value.getTotal() / perPage);
      if (storyListRef.value.length === 0 && page) {
        router.replace(route.path);
      }
    },
    { immediate: true },
  );
});
</script>

<template>
  <div :class="['blog-overview-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <template v-if="storyListRef">
        <MopBlogNewsItem v-for="story in storyListRef" :key="story.getUuId()" :story="story" />
        <UiPagination
          v-if="totalPagesRef > 1"
          :key="`blog-${totalPagesRef}-${$route.query.fullPath}-${$route.query.page}`"
          :page="$route.query.page?.toString()"
          :total-pages="totalPagesRef"
          class="blog-overview__pagination"
          :get-locale-path-by-page="getLocalePathByPage"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.blog-overview__pagination {
  margin-top: $space30;
}
</style>
